import request from '@/utils/request'

export function getActivity(parameter) {
  return request({
    url: '/activity',
    method: 'get',
    params: parameter
  })
}

export function queryActivity(parameter) {
  return request({
    url: '/activity',
    method: 'post',
    data: parameter
  })
}

export function deleteActivity(parameter) {
  return request({
    url: '/activity/del/' + parameter.id,
    method: 'delete'
  })
}

export function putActivity(parameter) {
  return request({
    url: '/activity',
    method: 'put',
    data: parameter
  })
}

export function queryActivitySign(parameter) {
  return request({
    url: '/activity/sign/page',
    method: 'get',
    params: parameter
  })
}

export function queryActivityTicket(parameter) {
  return request({
    url: '/activity/ticket/page',
    method: 'POST',
    data: parameter
  })
}

export function putActivityTicketDate(parameter) {
  return request({
    url: '/activity/ticket/update',
    method: 'POST',
    data: parameter
  })
}

export function deleteActivityTicket(parameter) {
  return request({
    url: '/activity/ticket/del/' + parameter.id,
    method: 'delete'
  })
}

export function orderJoinActivity(parameter) {
  return request({
    url: '/activity/join/order',
    method: 'POST',
    data: parameter
  })
}

export function queryActivityOrder(parameter) {
  return request({
    url: '/activity/order/page',
    method: 'get',
    params: parameter
  })
}

export function queryActivityApply(parameter) {
  return request({
    url: '/activity/apply/page',
    method: 'get',
    params: parameter
  })
}

export function putActivityApply(parameter) {
  return request({
    url: '/activity/apply/update',
    method: 'post',
    data: parameter
  })
}

export function deleteActivityApply(parameter) {
  return request({
    url: '/activity/apply/delete/' + parameter.id,
    method: 'delete'
  })
}