<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='sticky'>
        <a-select v-model="form.sticky" placeholder="活动类型" style="width: 250px">
          <a-select-option value="">
            活动类型
          </a-select-option>
          <a-select-option v-for="item in stickyList" :key="item.id" :value="item.id">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='masterName'>
        <a-input v-model='form.masterName' style="width: 350px" placeholder="负责人" />
      </a-form-model-item>
      <a-form-model-item prop='status'>
        <a-select v-model="form.status" placeholder="状态" style="width: 250px">
          <a-select-option value="">
            状态
          </a-select-option>
          <a-select-option :value="0">
            筹备中
          </a-select-option>
          <a-select-option :value="1">
            进行中
          </a-select-option>
          <a-select-option :value="2">
            已结束
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <a-button type='primary' icon='plus' @click='initialDeliver1'>新增活动</a-button>
      </div>
      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='activityTitle' slot-scope='text, record' @dblclick="initialDeliver2(record)">
          <template>
            {{text}}
          </template>
        </span>
        <!-- <span slot='ticketCount' slot-scope='text, record'>
          <template>
            <a @click='initialDeliver3(record)'>
              {{ text }}
            </a>
          </template>
        </span>         -->
        <span slot='orderCount' slot-scope='text, record'>
          <template>
            <a @click='initialDeliver5(record)'>
              {{ text }}
            </a>
          </template>
        </span>
        <span slot='sticky' slot-scope='text'>
          <template>
            {{ stickyList.find(item => item.id == text).name }}
          </template>
        </span>
        <span slot='status' slot-scope='text'>
          <template>
            <a-badge dot v-if='text=="0"' status='error' :text='"筹备中"' />
            <a-badge dot v-else-if='text=="1"' status='success' :text='"进行中"' />
            <a-badge dot v-else-if='text=="2"' :text='"已结束"' />
            <a-badge dot v-else status='default' :text='"其他"' />
          </template>
        </span>
        <span slot='result' slot-scope='text'>
          <template>
            {{text > 40 ? "通关榜样" : text > 30 ? "表现优异" : text > 20 ? "氛围不错" : "继续努力"}}
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a @click='initialDeliver4(record)'>关联订单</a>
              <a @click='initialDeliver1(record)'>编辑</a>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
              <a @click='handleDown(record)'>下载二维码</a>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>

    <a-modal :title='deliver1.form.id ? "编辑活动" : "新增活动"' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>
        <a-form-model-item label='活动名称' prop='title'>
          <a-input v-model='deliver1.form.title' />
        </a-form-model-item>
        <a-form-model-item label='活动类型' prop='sticky'>
          <a-select v-model="deliver1.form.sticky" placeholder="活动类型">
            <a-select-option v-for="item in stickyList" :key="item.id" :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item label='活动主描述' prop='activityMainDes'>
          <a-input v-model='deliver1.form.activityMainDes' />
        </a-form-model-item> -->
        <a-form-model-item label='活动城市' prop='activityCity'>
          <a-input v-model='deliver1.form.activityCity' />
        </a-form-model-item>
        <a-form-model-item label='负责人' prop='activityMasterMember'>
          <a-select show-search v-model='deliver1.form.activityMasterMember'
                    :default-active-first-option='false'
                    :filter-option='false'
                    :allowClear='true'
                    placeholder="请选择"
                    @change="handleChange1"
                    @search='handleSearch1'>
            <a-select-option v-for='item in  presidentMerchantList' :key='item.id'>
              {{ item.masterName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="活动开始时间" prop='startDate'>
          <a-date-picker :valueFormat="createFormatType" v-model="deliver1.form.startDate" showTime placeholder="选择日期时间"/>
        </a-form-model-item>
        <a-form-model-item label="活动结束时间" prop='endDate'>
          <a-date-picker :valueFormat="createFormatType" v-model="deliver1.form.endDate" showTime placeholder="选择日期时间"/>
        </a-form-model-item>
        <a-form-model-item label="二维码截止时间" prop='qrEndDate'>
          <a-date-picker :valueFormat="createFormatType" v-model="deliver1.form.qrEndDate" showTime placeholder="选择日期时间"/>
        </a-form-model-item>
        <a-form-model-item label='活动背景图' prop='cover'>
          <a-upload
            list-type='picture-card'
            :file-list="deliver1.form.cover ? [{
              uid: '-1',
              name: 'cover',
              status: 'done',
              url: deliver1.form.cover,
            }] : []"
            :customRequest='(event) => {handleUpload(event, "cover")}'
            :remove='() => deliver1.form.cover = ""'>
            <div v-if="!deliver1.form.cover">
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label='活动详细描述' prop='contentRichText'>
          <a-textarea v-model='deliver1.form.contentRichText' />
        </a-form-model-item>
        <a-form-model-item label='活动信息权限' prop='activityPermissionMember'>
          <a-select show-search v-model='deliver1.form.activityPermissionMember'
                    :default-active-first-option='false'
                    :filter-option='false'
                    :allowClear='true'
                    placeholder="请选择"
                    @change="handleChange2"
                    @search='handleSearch2'>
            <a-select-option v-for='item in merchantList' :key='item.id'>
              {{ item.masterName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='签到人员' :footer="null" :destroyOnClose='true' :maskClosable='true' :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false' :width="1000">
      <div style="line-height: 30px;">共：{{deliver2.total}}</div>
      <s-table id="table1" ref='table1' rowKey='id' size='default' :columns='columns1' :scroll='{ x: 1000}' :data='loadData1'>
        <span slot='mobile' slot-scope='text,record'>
          <template>
            <span v-for="(item, index) in getSpreaderMobileList(text, record.memberType == 'normal' ? record.memberOrderType : 0, record.memberType)" :key="index" :style="{color : item.color}">{{item.text}}</span>
          </template>
        </span>
        <span slot='spreaderNickname' slot-scope='text,record'>
          <template>
            {{ record.spreaderType == "normal" ? record.spreaderNickname : record.spreaderMasterName }}
          </template>
        </span>
        <span slot='spreaderMobile' slot-scope='text,record'>
          <template>
            <span v-for="(item, index) in getSpreaderMobileList(text, record.spreaderOrderType, record.spreaderType)" :key="index" :style="{color : item.color}">{{item.text}}</span>
          </template>
        </span>
      </s-table>
    </a-modal>

    <a-modal title='门票' :footer="null" :destroyOnClose='true' :maskClosable='true' :visible='deliver3.visible' @cancel='()=>this.deliver3.visible=false' :width="1200">
      <s-table id="table2" ref='table2' rowKey='id' size='default' :columns='columns2' :scroll='{ x: 1000}' :data='loadData2'>
        <span slot='useDate' slot-scope='text,record'>
          <template>
            <a-date-picker valueFormat="YYYY-MM-DD" v-model="record.useDate" placeholder="选择日期时间" @change="handleUseDateChange(record)" />
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleTicketDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </a-modal>

    <a-modal title='关联订单' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver4.visible' @cancel='()=>this.deliver4.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver4' :confirmLoading='deliver4.loading'>
      <a-form-model ref='form4' :model='deliver4.form' :rules='deliver4.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-form-model-item label='订单号' prop='orderId'>
          <a-input v-model='deliver4.form.orderId' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='下单活动明细' :footer="null" :destroyOnClose='true' :maskClosable='true' :visible='deliver5.visible' @cancel='()=>this.deliver5.visible=false' :width="1000">
      <div style="line-height: 30px;">共：{{deliver5.total}}</div>
      <s-table id="table3" ref='table3' rowKey='id' size='default' :columns='columns3' :scroll='{ x: 1000}' :data='loadData3'>
        <span slot='spreaderMobile' slot-scope='text,record'>
          <template>
            <span v-for="(item, index) in getSpreaderMobileList(text, record.spreaderOrderType, record.spreaderType)" :key="index" :style="{color : item.color}">{{item.text}}</span>
          </template>
        </span>
      </s-table>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { queryMerchant } from '@/api/member'
import { queryActivity, queryActivitySign, putActivity, deleteActivity, queryActivityTicket, putActivityTicketDate,deleteActivityTicket, orderJoinActivity, queryActivityOrder } from '@/api/activity'
import moment from 'moment'
import { mapGetters } from 'vuex'
import client from '@/config/oss.config.js'
import { getSpreaderMobileList } from '@/utils/util'

const columns = [
  {
    width: 180,
    title: '活动ID',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 150,
    title: '活动名称',
    dataIndex: 'title',
    scopedSlots: { customRender: 'activityTitle' }
  },
  {
    width: 120,
    title: '活动类型',
    dataIndex: 'sticky',
    scopedSlots: { customRender: 'sticky' }
  },
  {
    width: 100,
    title: '活动城市',
    dataIndex: 'activityCity',
    scopedSlots: { customRender: 'activityCity' }
  },
  {
    width: 100,
    title: '实到人数',
    dataIndex: 'actualSignCount',
    scopedSlots: { customRender: 'actualSignCount' }
  },
  //   {
  //   width: 100,
  //   title: '门票数',
  //   dataIndex: 'ticketCount',
  //   scopedSlots: { customRender: 'ticketCount' }
  // },
    {
    width: 100,
    title: '订单数',
    dataIndex: 'orderCount',
    scopedSlots: { customRender: 'orderCount' }
  },
    {
    width: 100,
    title: '负责人',
    dataIndex: 'activityMasterMemberName',
    scopedSlots: { customRender: 'activityMasterMemberName' }
  },
  {
    width: 200,
    title: '活动开始时间',
    dataIndex: 'startDate',
    scopedSlots: { customRender: 'startDate' }
  },
  {
    width: 100,
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    width: 100,
    title: '促销业绩',
    dataIndex: 'orderAmount',
    scopedSlots: { customRender: 'orderAmount' }
  },
  {
    width: 100,
    title: '活动成绩',
    dataIndex: 'result',
    scopedSlots: { customRender: 'result' }
  },
  {
    width: 250,
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const columns1 = [
  {
    width: 100,
    title: '用户昵称',
    dataIndex: 'nickname',
    scopedSlots: { customRender: 'nickname' }
  },
  {
    width: 100,
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    width: 100,
    title: '签到时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 100,
    title: '咨询人手机号',
    dataIndex: 'spreaderMobile',
    scopedSlots: { customRender: 'spreaderMobile' }
  },
  {
    width: 100,
    title: '咨询人姓名',
    dataIndex: 'spreaderNickname',
    scopedSlots: { customRender: 'spreaderNickname' }
  },
  {
    width: 100,
    title: '商户负责人',
    dataIndex: 'merchantMasterName',
    scopedSlots: { customRender: 'merchantMasterName' }
  }
]

const columns2 = [
  {
    width: 100,
    title: '订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '购买人',
    dataIndex: 'memberNickName',
    scopedSlots: { customRender: 'memberNickName' }
  },
  {
    width: 100,
    title: '商户负责人',
    dataIndex: 'merchantMasterName',
    scopedSlots: { customRender: 'merchantMasterName' }
  },
  {
    width: 100,
    title: '门票获得数',
    dataIndex: 'ticketQuantity',
    scopedSlots: { customRender: 'ticketQuantity' }
  },
  {
    width: 100,
    title: '使用日期',
    dataIndex: 'useDate',
    scopedSlots: { customRender: 'useDate' }
  },
  {
    width: 100,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const columns3 = [
  {
    width: 100,
    title: '订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '购买人姓名',
    dataIndex: 'userName',
    scopedSlots: { customRender: 'userName' }
  },
  {
    width: 100,
    title: '手机号',
    dataIndex: 'userName',
    scopedSlots: { customRender: 'userName' }
  },
  {
    width: 100,
    title: '咨询人手机号',
    dataIndex: 'spreaderMobile',
    scopedSlots: { customRender: 'spreaderMobile' }
  },
  {
    width: 100,
    title: '咨询人姓名',
    dataIndex: 'spreaderUserName',
    scopedSlots: { customRender: 'spreaderUserName' }
  },
  {
    width: 100,
    title: '所属商户',
    dataIndex: 'orderMerchantMasterName',
    scopedSlots: { customRender: 'orderMerchantMasterName' }
  }
]

const formatType = 'YYYY-MM-DD';
const baseStart = "";
const baseEnd = "";

const createFormatType = 'YYYY-MM-DD HH:mm:ss';

export default {
  name: 'ActivityList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      formatType,
      createFormatType,
      dateArr: [baseStart, baseEnd],
      form: {
        sticky: "",
        status: "",
        start: baseStart,
        end: baseEnd,
      },
      loading: false,
      dashboard: {}, //订单统计数据
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      loadData: parameter => {
        return queryActivity(Object.assign(parameter, this.queryParam, this.form))
          .then(datum => {
            return datum
          })
      },
      merchantList: [],
      presidentMerchantList: [],
      deliver1: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {          
          title: [{ required: true, message: '请输入活动名称', trigger: 'change' }],
          sticky: [{ required: true, message: '请选择活动类型', trigger: 'change' }],
          activityCity: [{ required: true, message: '请输入活动城市', trigger: 'change' }],
          activityMasterMember: [{ required: true, message: '请选择负责人', trigger: 'change' }],
          startDate: [{ required: true, message: '请选择', trigger: 'change' }],
          endDate: [{ required: true, message: '请选择', trigger: 'change' }],
          qrEndDate: [{ required: true, message: '请选择', trigger: 'change' }],
          cover: [{ required: true, message: '请选择', trigger: 'change' }],
          // activityPermissionMember: [{ required: true, message: '请选择活动信息权限', trigger: 'change' }],
        },
      },
      deliver2: { 
        visible: false, 
        loading: false,
        form: {},
        total: 0
      },
      // 表头
      columns1,
      loadData1: parameter => {
        return queryActivitySign(Object.assign(parameter, {id: this.deliver2.form.id}))
          .then(datum => {
            this.deliver2.total = datum.total;
            return datum
          })
      },
      deliver3: { 
        visible: false, 
        loading: false,
        form: {},
      },
      deliver4: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {          
          orderId: [{ required: true, message: '订单号', trigger: 'change' }],
        },
      },
      // 表头
      columns2,
      loadData2: parameter => {
        return queryActivityTicket(Object.assign(parameter, {activityId: this.deliver3.form.id}))
          .then(datum => {
            return datum
          })
      },
      deliver5: { 
        visible: false, 
        loading: false,
        form: {},
        total: 0
      },
      // 表头
      columns3,
      loadData3: parameter => {
        return queryActivityOrder(Object.assign(parameter, {id: this.deliver5.form.id}))
          .then(datum => {
            this.deliver5.total = datum.total;
            return datum
          })
      },
    }
  },
  computed: {
    ...mapGetters(["stickyList"])
  },
  created() {},
  methods: {
    moment,
    getSpreaderMobileList,
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    async handleUpload(event, key) {
      this.loading = true
      let result = await client.put('activity/' + event.file.uid, event.file)
      this.$set(this.deliver1.form, key, result.url)
      this.loading = false
      this.$refs.form1.clearValidate()
    },
    handleChange1(e) {
      this.$set(this.deliver1.form, "activityMasterMember", e)
    },
    handleSearch1(keyword) {
      queryMerchant({ current: 1, size: 10, type: "president", keyword: keyword }).then(result => {
        this.presidentMerchantList = result.records;
        if (this.deliver1.form.activityMasterMember && this.deliver1.form.activityMasterMemberName && this.presidentMerchantList.findIndex(item => item.id == this.deliver1.form.activityMasterMember) < 0) {
          this.presidentMerchantList.unshift({
            id: this.deliver1.form.activityMasterMember,
            masterName: this.deliver1.form.activityMasterMemberName,
          })
        }
      })
    },
    handleChange2(e) {
      this.$set(this.deliver1.form, "activityPermissionMember", e)
    },
    handleSearch2(keyword) {
      queryMerchant({ current: 1, size: 10, keyword: keyword  }).then(result => {
        this.merchantList = result.records;
        if (this.deliver1.form.activityPermissionMember && this.deliver1.form.activityPermissionMemberName && this.merchantList.findIndex(item => item.id == this.deliver1.form.activityMasterMember) < 0) {
          this.merchantList.unshift({
            id: this.deliver1.form.activityPermissionMember,
            masterName: this.deliver1.form.activityPermissionMemberName,
          })
        }
      })
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = baseStart;
      this.form.end = baseEnd;
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    initialDeliver5(record) {
      this.deliver5.form = Object.assign({}, record);
      this.deliver5.loading = false
      this.deliver5.visible = true
      if (this.$refs.table3) {
          this.$refs.table3.refresh(true)
      }
    },
    initialDeliver4(record) {
      this.deliver4.form = Object.assign({}, {id: record.id});
      this.deliver4.loading = false
      this.deliver4.visible = true
    },
    handleDeliver4() {
      this.$refs.form4.validate((result) => {
        console.log(result)
        if (result) {
          this.deliver4.loading = true;
          orderJoinActivity(this.deliver4.form).then(_result => {
            this.$message.success('操作成功')
            this.deliver4.visible = false
            this.deliver4.loading = false;
          }).catch(() => {
            this.deliver4.loading = false;
          })
        }
      })
    },
    initialDeliver3(record) {
      this.deliver3.form = Object.assign({}, record);
      this.deliver3.loading = false
      this.deliver3.visible = true
      if (this.$refs.table2) {
          this.$refs.table2.refresh(true)
      }
    },
    initialDeliver2(record) {
      this.deliver2.form = Object.assign({}, record);
      this.deliver2.loading = false
      this.deliver2.visible = true
      if (this.$refs.table1) {
          this.$refs.table1.refresh(true)
      }
    },
    initialDeliver1(record) {
      this.handleSearch1()
      this.handleSearch2()
      this.deliver1.form = Object.assign({}, record);
      this.deliver1.loading = false
      this.deliver1.visible = true
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        console.log(result)
        if (result) {
          this.deliver1.loading = true;
          putActivity(this.deliver1.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver1.visible = false
            this.deliver1.loading = false;
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    handleDelete(record) {
      deleteActivity({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleTicketDelete(record) {
      deleteActivityTicket({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table2.refresh(true)
      })
    },
    handleDown(record) {
      // console.log(`${process.env.VUE_APP_API_BASE_URL}/activity/suncode?id=${record.id}&page=pages/activitySign/activitySign`)
      window.open(`${process.env.VUE_APP_API_BASE_URL}/activity/suncode?id=${record.id}&page=pages/activitySign/activitySign`)
    },
    handleUseDateChange(record) {
      putActivityTicketDate(record).then(_result => {
        this.$message.success('操作成功')
      })
    }
  }
}
</script>
<style lang="less" scoped>
</style>
